import { Seo } from 'components';
import React, { useRef } from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { Quiz, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import { BenefitsPublication } from './start/results/components/BenefitsPublication';
import theme from 'utils/theme';
import styled from 'styled-components';
import { Expectations } from './start/checkout/components/Expectations';
import { VideoTestimonials } from './start/checkout/components/VideoTestimonials';
import { ReviewCards } from './start/checkout/components/ReviewCards';
import { mobile, useQuery } from 'styles/breakpoints';
import PeopleAskUs from 'components/PeopleAskUs';
import { WithWithoutLanding } from './sections/WithWithoutLanding';
import StartNowButton from '../pages/start/results/components/StartNowButton';
import { ResultsFooter } from './start/results/components/ResultsFooter';
import LCFooter from 'components/LCFooter';
import Tracking from 'utils/tracking';

enum QuestionType {
  Single = 'single',
  Multiple = 'multiple',
  MultipleImages = 'multiple_images',
  Encouragement = 'encouragement',
  Testimonial = 'testimonial',
  Info = 'info',
  Landing = 'landing',
  Landing2 = 'landing2',
  Image = 'image',
  Slider = 'slider',
  Scale = 'scale',
  Input = 'input',
  PreviousMultiple = 'previous_multiple',
  Insert = 'insert',
  Cost = 'cost',
}

const Home: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const questions = useSelector((state: AppState) => state.funnel.questions);
  const question = questions[0];
  const { isTablet } = useQuery();
  const landingRef = useRef(null);
  const { goToQuizStart } = useRouter();
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  const q = new URLSearchParams(location.search).get('q') ?? '1';

  const logEvent = (data: { category: string; label?: string }) => {
    Tracking.trackQuizQuestionAnswered({
      event: `Quiz question answered - ${quiz}`,
      category: `${q} - ${data.category}`,
      label: data.label || '',
    });
  };

  const handleLandingOptionSelect = (answer: QuizAnswer) => {
    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(answer.label),
    });
    delete answer.label;
    dispatch(updateQuizAnswers(answer));
    goToQuizStart();
  };

  const handleScroll = (flag?: boolean) => {
    landingRef?.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const renderSections = (item: any, index: number) => {
    const key = `${item}-${index}`;
    switch (item.type) {
      case 'publications': {
        return <BenefitsPublication key={key} {...item} />;
      }
      case 'with_without': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor={theme.colors.novaPink}
          >
            <WithWithoutLanding key={key} {...item} />
            <StyledButton onClick={handleScroll}>Take the quiz</StyledButton>
          </ContentWrapper>
        );
      }
      case 'expectations': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor="#436460"
          >
            <Expectations {...item} />
            <StyledButton
              bgColor="#FEF3F3"
              fontColor="#000"
              onClick={handleScroll}
            >
              Take the quiz
            </StyledButton>
          </ContentWrapper>
        );
      }
      case 'video_testimonials': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor="#436460"
          >
            <VideoTestimonials {...item} />
          </ContentWrapper>
        );
      }
      case 'review_cards': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor={theme.colors.novaPink}
          >
            <ReviewCards
              fontColor="dark100"
              scrollTo={handleScroll}
              buttonText="Take the quiz"
              {...item}
            />
          </ContentWrapper>
        );
      }
      case 'peopleAskUsSection': {
        return (
          <ContentWrapper
            key={key}
            $bgColor={theme.colors.novaPink}
            $padding={isTablet ? '0rem 1rem' : '2.25rem 1rem'}
          >
            <CenterContent
              $width={'776px'}
              $maxWidth={isTablet ? '100%' : '776px'}
            >
              <PeopleAskUs {...item} />
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'quiz_footer': {
        return (
          <ResultsFooter
            bgColor={'#FEF3F3'}
            fontColor={'#000'}
            key={key}
            {...item}
          />
        );
      }
      default:
        break;
    }
  };

  return (
    <>
      <Seo />
      {question?.type === QuestionType.Landing ? (
        <div ref={landingRef}>
          <Landing
            hideFooter
            question={question}
            onOptionSelect={handleLandingOptionSelect}
          />
        </div>
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )}
      {quiz === Quiz.Main || quiz === 'nvga2'
        ? question?.sections.map((item, index) => renderSections(item, index))
        : null}
      {(quiz === Quiz.Main || quiz === 'nvga2') && (
        <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
      )}
    </>
  );
});

Home.displayName = 'Home';

export default Home;

const StyledButton = styled(StartNowButton)<{
  bgColor?: string;
  fontColor?: string;
}>`
  width: 100%;
  max-width: 21.4375rem;
  margin: 3rem auto 0rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  @media ${mobile} {
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;

const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
`;
