import './assets/fonts/fonts.css';
import './components/payments/Payments';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { configStore } from 'state/store';
import { BodyWrapper, withApp } from 'components';
import { Modals, Navigations } from 'layouts';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import theme from './utils/theme';
import { InitialLoader } from './components';

const { store, persistor } = configStore();

export const wrapRootElement = ({ element }: { element: any }) => {
  if (typeof window === 'undefined') {
    return (
      <StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              <BodyWrapper>
                <InitialLoader />
                {/* <ContentLoader /> */}
                <Navigations />
                {withApp(element)}
                <Modals />
                {/* <Footers /> */}
              </BodyWrapper>
            </PersistGate>
          </ThemeProvider>
        </Provider>
      </StrictMode>
    );
  }
  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <BodyWrapper>
              <InitialLoader />
              {/* <ContentLoader /> */}
              <Navigations />
              {withApp(element)}
              <Modals />
              {/* <Footers /> */}
            </BodyWrapper>
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </StrictMode>
  );
};

export { store };
